import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'courseCheckbox' ]

  toggleCheckbox() {
    if (this.courseCheckboxTarget.disabled) { return }
    this.courseCheckboxTarget.checked = !this.courseCheckboxTarget.checked
  }
}
