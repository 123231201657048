import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="add-this-button"
export default class extends Controller {
  static targets = [ 'addThisButton', 'chooseCoursesButton' ]

  swapToChooseCoursesButton() {
    this.addThisButtonTarget.classList.add('is-hidden')
    this.chooseCoursesButtonTarget.classList.remove('is-hidden')
  }

  switchToChooseCoursesTab() {
    const changeTabEvent = new CustomEvent('changeTabById', { detail: 'choose-courses' })
    document.dispatchEvent(changeTabEvent)
  }
}
