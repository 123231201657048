// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "./controllers"

Rails.start()
ActiveStorage.start()

// Font Awesome icons
import "@fortawesome/fontawesome-free/js/all"

import LocalTime from 'local-time'
LocalTime.start()
import "trix"
import "@rails/actiontext"

import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(ChartDataLabels)
window.Chart = Chart
