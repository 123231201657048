import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-visibility"
export default class extends Controller {
  static targets = [
    'show',
    'hide'
  ]

  toggle() {
    this.hideTarget.classList.add('is-hidden')
    this.showTarget.classList.remove('is-hidden')
  }
}
