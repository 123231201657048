import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-upload"
export default class extends Controller {
  connect() {
    // See: https://bulma.io/documentation/form/file/#javascript
    const fileInput = document.querySelector('#file-js input[type=file]')
    fileInput.onchange = () => {
      if (fileInput.files.length > 0) {
        const fileName = document.querySelector('#file-js .file-name')
        fileName.textContent = fileInput.files[0].name
      }
    }
  }
}
