import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="materials-breakdown-chart"
export default class extends Controller {
  static targets = [
    'canvas'
  ]

  static values = {
    labels: String,
    values: String
  }

  connect() {
    let labels = JSON.parse(this.labelsValue)
    let values = JSON.parse(this.valuesValue)

    let total = values.shift() // Get the total from the first of the array and delete it from the array as we don't want the total in the chart.

    // green, red, yellow, primary (blue), orange. See app/assets/stylesheets/_variables.scss
    let colors = ['#16a085', '#e74c3c', '#f1b70e', '#34495e', '#d35400']
    let label_colors = ['#34495e', '#ecf0f1', '#34495e', '#ecf0f1', '#ecf0f1']

    new Chart(this.canvasTarget.getContext('2d'), {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          data: values,
          fill: false,
          backgroundColor: colors,
          borderColor: colors,
          tension: 0.1
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: label_colors,
            formatter: function(value, context) {
              return Math.round((value / total) * 100) + '%'
            }
          }
        }
      }
    })
  }
}
