import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-confirm"
export default class extends Controller {
  confirm(event) {
    let confirmed = confirm('Are you sure you want to remove this?')

    if(!confirmed) {
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
