import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This tab controller sets up an event listener so that you can programmatically change the tabs from the outside.
  // Create a CustomEvent with the detail value set to the tab index. Then dispatch it.
  // const changeTabEvent = new CustomEvent('changeTabByIndex', { detail: 1 })
  // document.dispatchEvent(changeTabEvent)

  // Or change it by HTML ID. This way assumes you have the link and the content divs named 'choose-courses-link' and 'choose-courses-content'
  // const changeTabEvent = new CustomEvent('changeTabById', { detail: 'choose-courses' })
  // document.dispatchEvent(changeTabEvent)

  // Stimulus provides cross-controller communication helpers, too. The events system used here is more vanilla JS.
  // https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events

  static values = { index: Number }
  static targets = [     
    'link',
    'content'
  ]

  connect() {
    this.showByIndex()
    this.setupChangeByEvent()
  }

  setupChangeByEvent() {
    document.addEventListener('changeTabByIndex', function (e) {
      this.indexValue = e.detail
      this.showByIndex()
    }.bind(this))

    document.addEventListener('changeTabById', function (e) {
      this.showById(e.detail)
    }.bind(this))
  }
  
  changeByLink(e) {
    this.indexValue = this.linkTargets.indexOf(e.target.parentNode)
    this.showByIndex()
  }

  showByIndex() {
    this.contentTargets.forEach((el, i) => {
      if (i == this.indexValue) {
        el.classList.remove('is-hidden')
      } else {
        el.classList.add('is-hidden')
      }
    })

    this.linkTargets.forEach((el, i) => {
      if (i == this.indexValue) {
        el.classList.add('is-active')
      } else {
        el.classList.remove('is-active')
      }
    })
  }

  showById(prefix) {
    this.contentTargets.forEach((el) => {
      if (el.id == `${prefix}-content`) {
        el.classList.remove('is-hidden')
      } else {
        el.classList.add('is-hidden')
      }
    })

    this.linkTargets.forEach((el) => {
      if (el.id == `${prefix}-link`) {
        el.classList.add('is-active')
      } else {
        el.classList.remove('is-active')
      }
    })
  }
}
