import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="course-select-validator"
export default class extends Controller {
  static targets = [ 'placeholderButton', 'submitButton', 'courseCheckbox' ]

  connect() {
    this.validate()
  }

  validate() {
    if (this.courseCheckboxTargets.filter(checkbox => checkbox.checked).length > 0) {
      this.placeholderButtonTargets.forEach( (elem) => { elem.classList.add('is-hidden') } )
      this.submitButtonTargets.forEach( (elem) => { elem.classList.remove('is-hidden') } )
    }
    else {
      this.placeholderButtonTargets.forEach( (elem) => { elem.classList.remove('is-hidden') } )
      this.submitButtonTargets.forEach( (elem) => { elem.classList.add('is-hidden') } )
    }
  }
}
