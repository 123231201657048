import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-sorter"
export default class extends Controller {
  connect() {
    this.setupTableSort()
  }

  // --------------------
  // Sort HTML table in browser.
  // Stolen from: https://stackoverflow.com/questions/14267781/sorting-html-table-with-javascript

  getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent

  comparer = (idx, asc) => (a, b) => ((v1, v2) => 
      v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
      )(this.getCellValue(asc ? a : b, idx), this.getCellValue(asc ? b : a, idx))

  setupTableSort() {
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table')
      const tbody = table.querySelector('tbody')
      Array.from(tbody.querySelectorAll('tr'))
        .sort(this.comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
        .forEach(tr => tbody.appendChild(tr) )
    })))
  }
  // --------------------
}
