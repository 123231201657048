import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="url-param"
export default class extends Controller {
  // The URL param value
  static targets = [ 'value' ]

  // The URL param name
  static values = {
    name: String
  }

  change() {
    let url = new URL(window.location.href)

    if (this.valueTarget.value == '' || this.valueTarget.value == null) {
      url.searchParams.delete(this.nameValue)

      // Special actions for certain names
      if (this.nameValue == 'sort_field') {
        url.searchParams.delete('sort_order')
      }
    } else {
      url.searchParams.set(this.nameValue, this.valueTarget.value)
      url.searchParams.delete('page') // Don't send page number to backend so that we start on page 1.

      // Special actions for certain names
      if (this.nameValue == 'sort_field') {
        if (!url.searchParams.has('sort_order')) {
          url.searchParams.set('sort_order', 'asc')
        }
      }
    }

    // Special actions for certain names
    if (this.nameValue == 'department_filter') {
      url.searchParams.delete('course_filter')
      url.searchParams.delete('section_code_filter')
    }

    if (this.nameValue == 'course_filter') {
      url.searchParams.delete('section_code_filter')
    }

    window.location = url
  }
}
