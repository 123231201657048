import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="due-date-chart"
export default class extends Controller {
  static targets = [
    'canvas'
  ]

  static values = {
    before: Number,
    after: Number
  }

  connect() {
    new Chart(this.canvasTarget.getContext('2d'), {
      type: 'doughnut',
      data: {
        labels: [`${this.beforeValue} Before Due Date`, `${this.afterValue} After Due Date`],
        datasets: [{
          data: [this.beforeValue, this.afterValue],
          fill: false,
          backgroundColor: ['#16a085', '#e74c3c'],
          borderColor: ['#16a085', '#e74c3c'],
          tension: 0.1
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            formatter: function(value, context) {
              return null // Hides the labels that ChartDataLabels is adding to every chart. Don't want them here.
            }
          }
        }
      }
    })
  }
}
