import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-chart"
export default class extends Controller {
  static targets = [
    'canvas'
  ]

  static values = {
    labels: String,
    values: String,
    dueDate: String
  }

  connect() {
    let labels = JSON.parse(this.labelsValue)
    let values = JSON.parse(this.valuesValue)
    let max_value = Math.max(...values)

    // To create a vertical line on the chart where the due date is, add a second dataset of type 'bar' for a bar chart.
    // To ensure the bar shows and lines up with the correct label, create an identical length dataset with only the 
    // due date label set to the max value of all values.
    let bar_values = []
    labels.forEach((label) => {
      if (label == this.dueDateValue) {
        bar_values.push(max_value)
      } else {
        bar_values.push(null)
      }
    })

    new Chart(this.canvasTarget.getContext('2d'), {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Adoptions By Date',
            data: values,
            fill: false,
            backgroundColor: '#f1b70e',
            borderColor: '#f1b70e',
            tension: 0.1,
            pointRadius: 0,
          },
          {
            label: 'Due Date',
            data: bar_values,
            backgroundColor: '#34495e',
            borderColor: '#34495e',
            type: 'bar',
            barThickness: 3
          }
        ]
      },
      options: {
        plugins: {
          datalabels: {
            formatter: function(value, context) {
              return null // Hides the labels that ChartDataLabels is adding to every chart. Don't want them here.
            }
          }
        }
      }
    })
  }
}
