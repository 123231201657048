import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="progress-chart"
export default class extends Controller {
  static targets = [
    'canvas'
  ]

  static values = {
    progress: Number
  }

  connect() {
    let completed = this.progressValue
    let needs_attention = 100 - this.progressValue

    new Chart(this.canvasTarget.getContext('2d'), {
      type: 'doughnut',
      data: {
        labels: [`${completed}% Completed`, `${needs_attention}% Needs Attention`],
        datasets: [{
          data: [completed, needs_attention],
          fill: false,
          backgroundColor: ['#16a085', '#e74c3c'],
          borderColor: ['#16a085', '#e74c3c'],
          tension: 0.1
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            formatter: function(value, context) {
              return null // Hides the labels that ChartDataLabels is adding to every chart. Don't want them here.
            }
          }
        }
      }
    })
  }
}
