import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  static targets = [ 'q', 'frame' ]

  updateUrl(url) {
    if (this.qTarget.value == '' || this.qTarget.value == null) {
      url.searchParams.delete('q')
    } else {
      url.searchParams.set('q', this.qTarget.value)
      url.searchParams.delete('page') // Don't send page number to backend so that search results start on page 1.
    }

    return url
  }

  submit() {
    let url = new URL(window.location.href)
    window.location = this.updateUrl(url)
  }

  submitOnEnter(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.submit()
    }
  }
  
  clear(event) {
    this.qTarget.value = ''
    event.preventDefault()
    this.submit()
  }

  submitFrame() {
    let url = new URL(this.frameTarget.src)
    this.frameTarget.src = this.updateUrl(url)
    this.frameTarget.reload()
  }

  submitFrameOnEnter(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.submitFrame()
    }
  }

  clearFrame(event) {
    event.preventDefault()
    this.qTarget.value = ''
    this.submitFrame()
  }
}