import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2/dist/sweetalert2'

// Connects to data-controller="success-toast"
export default class extends Controller {
  static targets = [     
    'message'
  ]

  connect() {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: true,
      confirmButtonText: 'Dismiss',
      confirmButtonColor: '#a9afb7', // $grey-light from _variables.scss
      timer: 6000,
      icon: 'success',
      text: this.messageTarget.innerText
  })
  }
}
