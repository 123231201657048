import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ia-ea-chart"
export default class extends Controller {
  static targets = [
    'canvas'
  ]

  static values = {
    total: Number,
    iaEa: Number
  }

  connect() {
    let total = this.totalValue

    new Chart(this.canvasTarget.getContext('2d'), {
      type: 'bar',
      data: {
        labels: ['IA/EA Courses', 'Total Courses'],
        datasets: [{
          data: [this.iaEaValue, this.totalValue],
          fill: false,
          backgroundColor: ['#f1b70e', '#34495e'],
          borderColor: ['#f1b70e', '#34495e'],
        }]
      },
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: ['#34495e', '#ecf0f1'],
            formatter: function(value, context) {
              if (context.dataIndex == 0) {
                return Math.round((value / total) * 100) + '%'
              } else {
                return null
              }
            }
          }
        }
      }
    })
  }
}
