import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-checkboxes"
export default class extends Controller {
  static targets = [ 'selectAll', 'checkbox' ]

  toggle() {
    this.checkboxTargets.forEach((el) => {
      if (this.selectAllTarget.checked) {
        el.checked = true
      } else {
        el.checked = false
      }
    })
  }
}
