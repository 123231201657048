import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="canned-notes"
export default class extends Controller {
  static targets = [ 'notes' ]

  append(event) {
    if (this.notesTarget.readOnly) { return }
    this.notesTarget.value += event.target.text
  }
}
