import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'school' ]

  change() {
    let url = new URL(window.location.href)

    if (this.schoolTarget.value == '' || this.schoolTarget.value == null) {
      url.searchParams.delete('school')
    } else {
      url.searchParams.set('school', this.schoolTarget.value)
    }

    window.location = url
  }
}