import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'modal', 'frame' ]
  
  open() {
    this.modalTarget.classList.add('is-active')
  }

  close() {
    this.modalTarget.classList.remove('is-active')
  }

  close_and_reload() {
    this.close()
    location.reload()
  }

  open_with_frame(event) {
    // I'm getting the URL this way because if it's set via Stimulus values on elements that were created with
    // an each loop, this controller can't see them or pull in the correct one.
    let url = event.target.dataset.frameUrl

    this.frameTarget.src = url
    this.open()
  }
}
